html, body {
    font-family: 'Raleway', Helvetica, Arial, sans-serif ;
}

h1 {
    font-size: 2rem;
}

h2 {
    font-size: 1.75rem;
}

h3 {
    font-size: 1.5rem;
}

h4 {
    font-size: 1.25rem;
}

p {
    font-size: 1rem;
    color: $color-grey;
    line-height: 1.4;
}