$breakpoints: (
  'sm':  ( min-width:  600px ),
  'md': ( min-width:  960px ),
  'lg':  ( min-width: 1280px ),
  'xl': ( min-width: 1920px )
) !default;

$max-width: 1140px;

// COLORS

$color-white: #fff;
$color-black: #000;
$color-grey: #282828;